.ol-box {
  box-sizing: border-box;
  background-color: #fff6;
  border: 1.5px solid #b3c5db;
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: #003c884d;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-line-inner {
  color: #eee;
  text-align: center;
  will-change: contents, width;
  border: 1px solid #eee;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-singlebar-even {
  background-color: #000;
}

.ol-scale-singlebar-odd {
  background-color: #fff;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  float: right;
  z-index: 10;
  background-color: #000;
}

.ol-scale-step-text {
  z-index: 11;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 12px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  height: 10px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
}

.ol-viewport canvas {
  all: unset;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: #fff6;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
}

.ol-control:hover {
  background-color: #fff9;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: #fff;
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  background-color: #003c8880;
  border: none;
  border-radius: 2px;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-size: 1.2em;
  font-weight: normal;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover, .ol-control button:focus {
  background-color: #003c88b3;
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  max-width: calc(100% - 1.3em);
  flex-flow: row-reverse;
  align-items: center;
  display: flex;
  bottom: .5em;
  right: .5em;
}

.ol-attribution a {
  color: #003c88b3;
  text-decoration: none;
}

.ol-attribution ul {
  color: #000;
  text-shadow: 0 0 2px #fff;
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: #fffc;
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  height: 150px;
  width: 150px;
  border: 1px solid #7b98bc;
  margin: 2px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: #fffc;
}

.ol-overviewmap-box {
  border: 2px dotted #003c88b3;
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  height: 80px;
  width: 100%;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

html, body {
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: DM Sans, sans-serif;
  font-size: 17px;
  overflow: hidden;
}

:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #003c88b3;
}

.map-container {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: DM Sans, sans-serif;
}

#map-canvas {
  height: 100%;
  width: 100%;
  margin: 0;
  display: block;
  position: relative;
}

#map-canvas.panel-top-toggle {
  height: 64%;
}

.year-display-text {
  display: float;
  font-size: 1.3rem;
  font-weight: 700;
  position: absolute;
  top: 4px;
  right: 8px;
}

#chart-container {
  width: 100%;
  height: 36%;
  background-color: #f2efe9;
  border-top: 4px double #6590b9;
  margin: 0;
  padding: 10px 10px 0;
  animation: chartOut .3s cubic-bezier(0, 0, .2, 1);
  display: none;
}

#chart-container.panel-bottom-toggle {
  justify-content: center;
  align-items: center;
  animation: chartIn .3s cubic-bezier(0, 0, .2, 1);
  display: flex;
}

#chart {
  width: 99%;
  height: 99%;
  display: inline-block;
}

.apexcharts-xaxis-label {
  padding: 1px;
  font-size: .8rem;
  display: none;
}

.apexcharts-xaxis-label:nth-child(2n+1) {
  display: revert;
}

.apexcharts-yaxis-label {
  font-size: .8rem;
}

.apexcharts-title-text {
  font-size: 1rem;
}

#toggle-button-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.toggle-button {
  background: url("chevron-up-outline.d61aed4c.svg") center / contain no-repeat;
}

.toggle-button-expanded {
  background: url("chevron-down-outline.230f841e.svg") center / contain no-repeat;
}

.toggle-button:hover {
  background-color: #3d6ea5;
}

.toggle-button:active {
  background-color: #003c88b3;
}

.ol-popup {
  background-color: #f2efe9;
  border-radius: 10px;
  font-family: DM Sans, sans-serif;
  animation: mmfadeIn .12s;
  position: absolute;
  bottom: 12px;
  left: -50px;
  box-shadow: 0 1px 4px #0003;
}

.ol-popup:after, .ol-popup:before {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  position: absolute;
  top: 100%;
}

.ol-popup:after {
  border-width: 10px;
  border-top-color: #fff;
  margin-left: -10px;
  left: 48px;
}

.ol-popup:before {
  border-width: 11px;
  border-top-color: #ccc;
  margin-left: -11px;
  left: 48px;
}

.ol-popup-closer {
  color: #f2efe9;
  padding: 2px;
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.ol-popup-closer:hover {
  color: red;
}

.ol-popup h1 {
  color: #fff;
  min-width: 240px;
  background-color: #6590b9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: .7rem 1.4rem;
  font-size: 1.1rem;
}

.ol-popup h2 {
  margin: .5rem 0;
  font-size: .85rem;
}

.ol-popup h3 {
  margin: .5rem 0;
  font-size: 1.3rem;
}

.ol-control button {
  background-color: #003c8880;
}

.ol-control button:focus {
  text-decoration: unset;
  background-color: #003c8880;
}

.ol-control button:focus-visible, .ol-control button:hover {
  background-color: #003c88b3;
}

.ol-control button:active {
  background-color: #003c88cc;
}

.play-button-container {
  bottom: 88px;
  left: 50%;
  transform: translateX(-50%);
}

.play-button-container button {
  height: 50px;
  width: 50px;
  margin: 1px;
}

.next-frame-button {
  bottom: 88px;
  left: 50%;
  transform: translateX(100%);
}

.prev-frame-button {
  bottom: 88px;
  left: 50%;
  transform: translateX(-200%);
}

.slider-element {
  width: 88%;
  height: 80px;
  background-color: #fff6;
  justify-content: center;
  padding-bottom: 4px;
  display: flex;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-element:hover {
  background-color: #fff6;
}

.slider-container-outer {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #003c8880;
  border-radius: 2px;
  margin: 1px;
  padding: 6px;
  display: table;
}

.slider-container-inner {
  width: 100%;
  padding: 6px 20px 0;
}

.slider-styled {
  height: 6px;
  font-family: DM Sans, sans-serif;
}

.slider-styled .noUi-handle {
  box-shadow: none;
  height: 24px;
  width: 8px;
  background: #6590b9;
  border-radius: 3px;
  top: -8px;
  right: -4px;
}

.slider-styled .noUi-handle:hover {
  background: #3d6ea5;
}

.slider-styled .noUi-handle:active {
  background-color: #145078f2;
}

.slider-styled .noUi-handle:before, .slider-styled .noUi-handle:after {
  display: none;
}

.slider-styled .noUi-value {
  color: #f2efe9;
  font-size: 1rem;
}

.slider-styled .noUi-marker-horizontal {
  background: #fff9;
  transform: translateY(-14px);
}

.slider-styled .noUi-marker-horizontal.noUi-marker-sub {
  height: 30% !important;
}

.slider-styled .noUi-marker-horizontal.noUi-marker-normal {
  height: 15% !important;
}

.slider-styled .noUi-handle .noUi-touch-area {
  width: auto;
  height: auto;
  border: 1px solid #0000;
  position: absolute;
  inset: -8px -20px -20px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: "";
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 5px solid #fff6;
  border-top-color: #003c8880;
  border-radius: 50%;
  margin-top: -20px;
  margin-left: -20px;
  animation: spinner .6s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

.modal {
  text-align: left;
  font-family: DM Sans, sans-serif;
  font-size: .8rem;
}

.modal__overlay {
  background: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal__container {
  max-width: 590px;
  max-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  overflow-y: auto;
}

.modal__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .7rem;
  display: flex;
}

.modal h3 {
  margin-bottom: .8rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
}

.modal__title {
  color: #6590b9;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

.modal__close {
  background: none;
  border: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal__close:hover {
  color: red;
}

.modal__header .modal__close:before {
  content: "✕";
}

.modal__content {
  color: #000c;
  line-height: 1.6rem;
}

.modal-img-inline {
  height: 1rem;
  vertical-align: middle;
  background-color: #6590b9;
  display: inline-block;
  transform: translateY(-2px);
}

.points-map-legend {
  text-align: center;
  padding: 12px;
}

#circle-small-green {
  width: 10px;
  height: 10px;
  background-color: #81c49c;
  border-radius: 100%;
}

#circle-med-green {
  width: 19px;
  height: 19px;
  background-color: #aac489;
  border-radius: 100%;
}

#circle-med-yellow {
  width: 28px;
  height: 28px;
  background-color: #c5c47c;
  border-radius: 100%;
}

#circle-med-orange {
  width: 37px;
  height: 37px;
  background-color: #c6b27b;
  border-radius: 100%;
}

#circle-big-red {
  width: 55px;
  height: 55px;
  background-color: #c6827b;
  border-radius: 100%;
}

.modal__btn {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  background-color: #6590b9;
  border-style: none;
  border-width: 0;
  border-radius: 10%;
  margin: 0 1rem 1.3rem;
  padding: .5rem 1rem;
  font-size: 1.5rem;
  line-height: 1.15;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  overflow: visible;
  transform: translateZ(0);
}

.modal__btn:focus-visible, .modal__btn:hover {
  transform: scale(1.05);
}

.modal__btn:active {
  background-color: #003c88b3;
}

.modal__btn-primary {
  color: #fff;
  background-color: #00449e;
}

.modal__footer {
  text-align: center;
  font-size: .7rem;
}

.modal footer p {
  margin-bottom: 0;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

@keyframes chartIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes chartOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container, .micromodal-slide .modal__overlay {
  will-change: transform;
}

@media only screen and (orientation: landscape) {
  #chart-container {
    height: 46%;
  }

  #map-canvas.panel-top-toggle {
    height: 54%;
  }
}

@media only screen and (min-width: 600px) {
  .apexcharts-xaxis-label:nth-child(2n+1) {
    display: revert;
  }
}

@media only screen and (min-width: 768px) {
  .apexcharts-xaxis-label:nth-child(n+1) {
    display: revert;
  }
}

@media only screen and (max-height: 680px) {
  .apexcharts-yaxis-label {
    display: none;
  }

  .apexcharts-yaxis-label:nth-child(2n+1) {
    display: revert;
  }

  .apexcharts-yaxis-title-text {
    font-size: 12px;
  }
}

@media only screen and (min-height: 992px) {
  .apexcharts-yaxis-label {
    display: revert;
  }
}

/*# sourceMappingURL=index.3c4328de.css.map */
